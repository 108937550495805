import {
  QueryHookOptions, QueryResult, gql, useQuery,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';
import store from '../common_lib_front/utilities/store';

const GET_APPLICATION = gql`
query GetApplication($userId: String!, $type: String) {
  getApplication(userId: $userId, type: $type) {
    data {
      approvedBy
      registrationId
      status
      type
      userId
      expireDate
    }
    error
    success
  }
}
`;

type GET_APPLICATION_VARS = {
  type: 'resident' | 'host' | 'vendor';
  userId: string;
}

export type getApplicationResponse = {
    approvedBy: string
    registrationId: string
    status: string
    type: string
    userId: string
    expireDate?: string | null
  }

type GET_APPLICATION_RES = {
  getApplication: backendResponse<getApplicationResponse | null>
}

type optionsType = QueryHookOptions<GET_APPLICATION_RES, GET_APPLICATION_VARS>;
type resType = QueryResult<GET_APPLICATION_RES, GET_APPLICATION_VARS>;

export const useGetApplication = (options?: optionsType): resType => useQuery<
  GET_APPLICATION_RES, GET_APPLICATION_VARS
>(GET_APPLICATION, {
  variables: {
    type: 'vendor',
    userId: store.getState().user?.userId || '',
  },
  ...options,
});

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { useGetApplication } from '../../../hooks/useGetApplication';
import { useSEtApplicationStatus } from '../../../hooks/useSetApplicationStatus';
import RegistrationNavHeader from '../registrationNavHeader/registrationNavHeader';
import style from './orderSummary.module.css';

export default function OrderSummary(): React.ReactElement {
  const { name: communityName } = useContext(CommunityContext);
  const { registrationId } = useParams<{ registrationId?: string }>();

  const [setApplicationStatus] = useSEtApplicationStatus();

  const { refetch } = useGetApplication({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: (d) => {
      if (['expired', 'incomplete'].includes(d.getApplication.data?.status as string)) {
        setApplicationStatus({
          variables: {
            status: 'pending-review',
            registrationId: registrationId ?? '',
          },
        })
          .then(() => {
            refetch();
          });
      }
    },
  });

  const { t } = useTranslation();
  const nowDate = new Date();

  // const downloadPdf = async () => {
  //   const pdf: any = await exportPdf(passes.map((p) => p.url));
  //   if (pdf) {
  //     pdf.save('access-passes.pdf');
  //   }
  // };

  // const printPdf = async () => {
  //   const pdf: any = await exportPdf(passes.map((p) => p.url));
  //   if (pdf) {
  //     const blob = pdf.output('blob');
  //     const nextWindow = window.open(URL.createObjectURL(blob), '_blank');
  //     nextWindow?.focus();
  //     nextWindow?.print();
  //   }
  // };

  const history = useHistory();

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          activeNum={5}
          title={t(`Thank you for registering at ${communityName}`)}
          subtitle=""
        />

        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <div className={style.infoBox}>
              <div className={style.innerInfoBox}>
                <h3 className={`${style.title} textColorSuperDark `}>
                  {t('Order Summary')}
                </h3>
                {/* no email is sent */}
                {/* <p className={`${style.text} textColorSuperDark `}>
                  {t('A confirmation email has been sent to your email')}
                </p> */}
                <ul>
                  <li>
                    <b>
                      {t('Registration Id')}
                      :
                      {' '}
                    </b>
                    <span>{registrationId}</span>
                  </li>
                  <li>
                    <b>
                      {t('Date')}
                      :
                      {' '}
                    </b>
                    <span>{nowDate.toLocaleDateString('en-US')}</span>
                  </li>
                </ul>
                <div className={style.btnBox}>
                  {/* <div className={style.btn}>
                    <GenericButton
                      color="blue"
                      outline="large"
                      title={communityId === 'sipoa' ? t('Print Pass voucher/QR code
                      & Receipt') : t('Print Passes & Receipt')}
                      clickHandler={() => {
                        printPdf();
                      }}
                    />
                    </div> */}
                  {/* <div className={style.btn}>
                    <GenericButton
                      size="large"
                      color="blue"
                      title={communityId === 'sipoa' ? t('Share Pass voucher/QR
                      code') : t('Share Pass')}
                      clickHandler={() => {
                        history.push('/vendor/my-passes');
                      }}
                    />
                  </div> */}
                  {/* <div className={style.btn}>
                    <GenericButton
                      size="large"
                      color="blue"
                      title={communityId === 'sipoa' ? t('Download Pass voucher/QR cod
                      e') : t('Download Access Pass')}
                      clickHandler={() => {
                        downloadPdf();
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </div>

            {/* <div className={style.instruction}>Custom Instruction from pass builder...</div> */}
          </div>

        </div>
        <div className={style.btnContinue}>
          <GenericButton
            size="large"
            color="blue"
            title="Complete"
            clickHandler={() => history.push('/vendor')}
          />
        </div>
      </div>
    </div>
  );
}
